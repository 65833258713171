import request from '@/utils/request'
// 登录
export function login(data) {
  return request({
    url: '/teacher/auth/login',
    method: 'post',
    data
  })
}
// 退出
export function logout(params) {
  return request({
    url: '/common/auth/logout',
    method: 'GET',
    params
  })
}
 
 
//  //post请求
// export function projectApproval(data) {
//   return request({
//     url: '/web/project/reply',
//     method: 'post',
//     data
//   })
// }
