import Cookies from 'js-cookie'

const TokenKey = 'WebSite-Token'

const IdentifierKey = 'Identifier-Token'

const RedirectTypeKey = 'redirectType'

const SignKey = 'sign'

const WebNameKey = 'WebName-Token'

const WebAvaterKey = 'WebAvater-Token'

const csrf_tokenKey = 'csrf_token'


//特殊接口需要带token
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getcsrf_tokenKey() {
  return Cookies.get(csrf_tokenKey)
}

export function setcsrf_tokenKey(csrf_token) {
  return Cookies.set(csrf_tokenKey, csrf_token)
}

export function removecsrf_tokenKey() {
  return Cookies.remove(csrf_tokenKey)
}

//用户身份信息，1：学生，2：老师，3：校外人员，4：管理员，单个直接取值放到header里面，多个需要弹窗选择身份
export function getIdentifierToken() {
  return Cookies.get(IdentifierKey)
}

export function setIdentifierToken(token) {
  return Cookies.set(IdentifierKey, token)
}

export function removeIdentifierToken() {
  return Cookies.remove(IdentifierKey)
}

export function getRedirecttypeToken() {
  return Cookies.get(RedirectTypeKey)
}

export function setRedirecttypeToken(Redirecttype) {
  return Cookies.set(RedirectTypeKey, Redirecttype)
}

export function removeRedirecttypeToken() {
  return Cookies.remove(RedirectTypeKey)
}

export function getSignToken() {
  return Cookies.get(SignKey)
}

export function setSignToken(Sign) {
  return Cookies.set(SignKey, Sign)
}

export function removeSignToken() {
  return Cookies.remove(SignKey)
}

//用户名字
export function getWebNameToken() {
  return Cookies.get(WebNameKey)
}

export function setWebNameToken(token) {
  return Cookies.set(WebNameKey, token)
}

export function removeWebNameToken() {
  return Cookies.remove(WebNameKey)
}

//用户头像
export function getWebAvaterToken() {
  return Cookies.get(WebAvaterKey)
}

export function setWebAvaterToken(token) {
  return Cookies.set(WebAvaterKey, token)
}

export function removeWebAvaterToken() {
  return Cookies.remove(WebAvaterKey)
}