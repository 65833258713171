<template>
  <div class="footer" style="padding-bottom: 20px;">
    <div class="item">
      地址：湖北荆门市象山大道33号 邮编：448000
      <!-- 传真：027-87384670 -->

    </div>
    <div class="item">
      Copyright ©2013-2019 荆楚理工学院 版权所有 技术支持：武汉有教文化传播有限公司
    </div>
    <div class="item">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color:aliceblue">
        鄂ICP备05003328号
      </a>
    </div>
    <div class="item">
      <a href="https://jcut.kzmooc.cn/uploads/images/2023/05/15/77f6d78c3d3d7eeb2bf31c7fbf59a0aa.jpg"
        target="_blank" style="color:aliceblue">
        <img :src="avater1" style="position: relative;top: 2px;left: -3px;" /> 信息系统安全等级保护备案证明
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {
      avater1: require("@/assets/bazm.png"),
    };
  },
};
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  height: 100%;
  background: #111;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;

  .item {
    font-size: 15px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    padding-top: 10px;

    .foot_icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../assets/footicon.png") 0 0 no-repeat;
      background-size: 100% 100%;
    }
  }
}</style>
