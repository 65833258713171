<template>
  <div class="main-head">
    <template v-if="isYufabu">
      <!-- <div style="color: red; width: 100%; font-size: 24px; font-weight: bold; text-align: center;">这是预发布环境,正式环境不会显示
      </div> -->
    </template>
    <div class="head" v-if="header_flag == 1">
      <div class="head-left">
        <el-image class="elimage" :src="logourl" />
      </div>
    </div>
    <div class="head" v-if="header_flag != 1">
      <div class="head-left" style="cursor: pointer;" @click="head_index">
        <!-- <el-image class="elimage" :src="logourl" v-if="schoolid == 1" />
        <el-image class="elimage" :src="logourl1" v-else /> -->
        <el-image class="elimage juctlogo" :src="logourl" />
      </div>
      <div class="head-center">
        <div :class="activebtid === index ? 'btitem btitemactive' : 'btitem'"
          @click="menubtclick(index, item.component)" v-for="(item, index) in btlist" :key="index">
          <!-- v-clickDown="index" -->
          {{ item.name }}
        </div>
      </div>

      <div class="head-right">
        <div class="admin_login" @click="tourl">管理后台登录</div>
        <div class="avater_info" v-if="token">
          <el-dropdown>
            <span class="el-dropdown-link span-title">
              <el-image style="margin-right: 4px" class="elimage" :src="useravater" />
              <span class="bt">{{ username }}</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="personalbt">个人中心</el-dropdown-item>
              <el-dropdown-item @click.native="loginout">安全退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-button v-if="!token" class="loginbt" @click="login">登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getToken,
  getWebNameToken,
  getWebAvaterToken,
  getcsrf_tokenKey,
  setWebAvaterToken,
  removeToken,
} from "@/utils/auth";
import { logout } from "@/api/login";
// import { response } from "express";
const request_base_path = require('../../src/utils/base_url')
export default {
  name: "Head",
  components: {},
  data() {
    return {
      avater1: require("@/assets/学生.png"),
      avater2: require("@/assets/老师.png"),
      avater3: require("@/assets/管理员.png"),
      avater4: require("@/assets/校外人员.png"),
      token: getToken() ? true : false,
      islog: false,
      logourl: require("@/assets/登录/jclglogo.png"),
      logourl1: require("@/assets/shifan02.png"),

      activeName: "first",
      header_flag: '',
      // activebtid: 0,
      schoolid: "",
      schoolinfo: {},
      btlist: [
        {
          name: "首页",
          component: "firstpage",
        },
        {
          name: "虚拟仿真实验",
          component: "virtualsimulationexperiment",
        },
        // {
        //   name: "线上课程",
        //   component: "wisdompracticeteaching",
        // },
        // {
        //   name: "实验用房预约",
        //   component: "openroom",
        // },

        // {
        //   name: "开放实验",
        //   component: "openexperiment",
        // },
        // {
        //   name: "安全准入",
        //   component: "safeaccess",
        // },
        // {
        //   name: "通知公告",
        //   component: "announcement",
        // },
        // {
        //   name: "新闻资讯",
        //   component: "news",
        // },
        // {
        //   name: "直播",
        //   component: "livebroadcast",
        // },
      ],
      isYufabu: false,
    };
  },
  created() {
    const pageUrl = window.location.href; // 获取页面完整地址
    if (pageUrl.indexOf("yufabu") === -1) {
      this.isYufabu = true;
    }else{
      this.isYufabu = false;
    }
    // this.isYufabu = request_base_path.pro_url === "https://shijian.hzau.edu.cn/yufabu/" ? true : false
    let header_flag = this.$route.query.header_flag
    this.header_flag = header_flag
    // this.menubtclick(0, 'firstpage');
  },
  mounted() { },

  computed: {
    activebtid() {
      return this.$store.state.menuactiveid;
    },
    username() {
      return this.$store.state.username;
    },
    useravater() {
      //获取用户头像 没有就显示默认头像
      let avater = this.$store.state.useravater;
      if (!avater) {
        let userType = Number(this.$store.state.identifier[0]);
        // console.log("人物类型", userType);
        switch (userType) {
          case 1:
            avater = this.avater1;
            break;
          case 2:
            avater = this.avater2;
            break;
          case 3:
            avater = this.avater3;
            break;
          case 4:
            avater = this.avater4;
            break;
        }
        this.$store.commit("SET_Useravater", avater);
        setWebAvaterToken(avater);
      }
      // console.log("设置的头像数据", avater);
      return avater;
    },
  },
  methods: {
    head_index() {
      this.menubtclick(0, 'firstpage');
      this.$router.push({
        path: "/home/firstpage"
      });

    },
    menubtclick(id, component) {
      window.localStorage.setItem("menuId", id);
      this.$store.dispatch("setmenuid", id);
      this.$router.push({
        path: "/home/" + component,
      });
    },
    personalbt() {
      sessionStorage.setItem('router-type', false)
      this.menubtclick(8, "personalcenter");

      let mypage = {
        active: 0,
        componentId: "Virtualsimulationexperiment"
      }
      sessionStorage.setItem('my-page', JSON.stringify(mypage))
    },
    login() {
      //原地址
      this.$router.push({ path: "/login" });
    },

    async loginout() {
      logout().then((response) => {
        if (response.code === 0) {
          localStorage.setItem('live_login_flag', "0")
          window.localStorage.removeItem("menuId");
          this.token = false;
          removeToken()
          console.log(request_base_path);
          if (process.env.NODE_ENV == 'development') {
            // location.href = request_base_path.dev_url + 'cas-logout'
            this.$router.push({ path: "/" });
          } else if (process.env.NODE_ENV == 'production') {
            // location.href = request_base_path.pro_url + 'cas-logout'  //正式服需要的
             this.$router.push({ path: "/" }); // 测试服需要的
          }
        }
      })
    },
    getUrlPramas(pramas) {
      //定义一个空对象来存储路径中获取的参数
      var object = {};

      //1.获取？后面的所有内容包括问号
      var url = location.hash.split("?")[1]; //?name=嘻嘻&hobby=追剧
      // 2.截取？后面的字符串
      let urlData = url.substr(0);

      var strs = urlData.split("&");

      for (var i = 0; i < strs.length; i++) {
        object[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
      return object;
    },
    tourl() {
      let token = getToken();
      if (token) {
        if (process.env.NODE_ENV == 'development') {
          let href = "http://192.168.2.90:9527/#/sign_on"; // 本地
          let params = "?schoolid=1&identifier=4&token=" + getToken();
          window.open(href + params, "_blank"); // 拼接外部网址
          // location.href = request_base_path.dev_url + '/cas-logout'
          // this.$router.push({ path: "/" });
        } else if (process.env.NODE_ENV == 'production') {
          let href = request_base_path.pro_url + 'teacher-admin/#/sign_on'; // 线上
          let params = "?schoolid=1&identifier=4&token=" + getToken();
          window.open(href + params, "_blank"); // 拼接外部网址
          // location.href = request_base_path.pro_url + '/cas-logout'  //正式服需要的
          //  this.$router.push({ path: "/" });//测试服需要的
        }
      } else {
        window.open(request_base_path.pro_url + "teacher-admin/#/login", "_blank"); // 拼接外部网址
      }
    },
  },
};
</script>
<style scoped lang="scss">
.main-head {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  background: #fff;

  .head {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin: 0 auto;

    .head-left {
      flex-basis: 245px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
    }

    .head-center {
      flex-grow: 1;
      flex-shrink: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .btitem {
        margin-left: 90px;
        user-select: none;
        white-space: nowrap;
        font-size: 15px;
        font-weight: 400;
        color: #555555;
        line-height: 60px;
        height: 60px;
        border-bottom: 3px solid transparent;
        cursor: pointer;

        &:first-child {
          margin-left: 53px;
        }

        &:hover {
          color: #3d84ff;
          border-bottom: 3px solid #3d84ff;
        }
      }

      .btitemactive {
        color: #3d84ff;
        border-bottom: 3px solid #3d84ff;
      }
    }

    .head-right {
      flex-basis: 221px;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      user-select: none;
      white-space: nowrap;

      .admin_login {
        user-select: none;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        height: 32px;
        line-height: 32px;
        margin-right: 20px;
        background: #3d84ff;
        padding: 0px 10px 0px 10px;
        border-radius: 5px;
        cursor: pointer;
        width: 105px;
        text-align: center;
      }

      .avater_info {
        display: flex;
        justify-content: center;
        align-items: center;

        .span-title {
          display: flex;
          align-items: center;
        }

        .elimage {
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }
      }

      .loginbt {
        margin-left: 30px;
        width: 85px;
        height: 32px;
        line-height: 32px;
        padding: 0px;
        background: #3d84ff;
        color: #ffffff;
      }

      .signout {
        margin-left: 12px;
        width: 85px;
        height: 32px;
        line-height: 32px;
        padding: 0px;
        background: #ffffff;
        color: #3d84ff;
      }
    }
  }
  .juctlogo{
    // filter: brightness(1) invert(1);
  }
}
</style>
