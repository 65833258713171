import request from '@/utils/request'

//修改个人信息
export function edituserinfo(data) {
    return request({
        url: '/portal/user/edit',
        method: 'post',
        data
    })
}

//修改密码
export function edituserresetpassword(data) {
    return request({
        url: '/portal/user/reset-password',
        method: 'post',
        data
    })
}

//更换手机号
export function changeusermobile(data) {
    return request({
        url: '/portal/user/change-mobile',
        method: 'post',
        data
    })
}  

//获取用户信息
export function getuserinfo(params) {
    return request({
        url: '/portal/user/get-info',
        method: 'GET',
        params
    })
}

//发送短信验证码
export function sendsmscode(data) {
    return request({
        url: '/common/sms/send-code',
        method: 'post',
        data
    })
}

//注册
export function registerUaserName(data) {
    return request({
        url: '/portal/auth/register',
        method: 'post',
        data
    })
}
